import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import rootAction from "../../../stateManagment/actions/rootAction";
import Config from "../../../helpers/Config";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import { showErrorMsg } from "../../../helpers/ValidationHelper";
import { Helmet } from "react-helmet";
import {
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import { Row, Col, Input, Label, Button } from "reactstrap";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [Phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const cartJsonDataSession = useSelector(
    (state) => state.cartReducer.cartItems
  );

  // Function to check if the user exists
  const isExisteUser = async () => {
    const paramCart = {
      requestParameters: {
        MobileNo: Phone,
        recordValueJson: "[]",
      },
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    try {
      const mobileData = await MakeApiCallAsync(
        Config.END_POINT_NAMES["ISEXCISTINGMOBILENO"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramCart,
        headers,
        "POST",
        true
      );

      if (mobileData && mobileData.data.statusCode === 200) {
        const parsedUserData = JSON.parse(mobileData.data.data);
        const emailAddress = parsedUserData.EmailAddress;
        setEmailAddress(emailAddress ?? "");

        const loginParam = {
          requestParameters: {
            Email: emailAddress,
            Password: "Password", // Replace with dynamic password if needed
          },
        };

        const response = await MakeApiCallAsync(
          Config.END_POINT_NAMES["GET_USER_LOGIN"],
          null,
          loginParam,
          headers,
          "POST",
          true
        );

        if (response != null && response.data != null) {
          let userData = JSON.parse(response.data.data);
          if (
            userData.length > 0 &&
            userData[0].ResponseMsg != undefined &&
            userData[0].ResponseMsg == "Login Successfully"
          ) {
            // Save user data in session
            localStorage.setItem("user", JSON.stringify(userData[0]));
            dispatch(
              rootAction.userAction.setUser(JSON.stringify(userData[0]))
            );

            // Set Token in local storage
            localStorage.setItem("Token", response.data.token ?? "");

            // Navigate to home page
            navigate("/");
          } else {
            showErrorMsg("Incorrect email or password!");
          }
        }
      }
    } catch (error) {
      console.error("Error in isExisteUser:", error);
      showErrorMsg("An error occurred while checking user existence.");
    }
  };

  // Submit Login Form
  const submitLoginForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (Phone && Phone.length === 10) {
        // Check if the user exists
        await isExisteUser();
      } else {
        showErrorMsg("Please enter a valid mobile number.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      showErrorMsg("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Localization Labels
  useEffect(() => {
    const dataOperationFunc = async () => {
      try {
        const localizationData = await GetLocalizationControlsJsonDataForScreen(
          GlobalEnums.Entities["Login"],
          null
        );
        if (localizationData?.length > 0) {
          setLocalizationLabelsArray(localizationData);
        }
      } catch (error) {
        console.error("Error fetching localization data:", error);
      }
    };
    dataOperationFunc();
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Login</title>
        <meta name="description" content={siteTitle + " - Login"} />
        <meta name="keywords" content="Login" />
      </Helmet>

      <SiteBreadcrumb title="Login" parent="home" />

      <section className="login-page section-big-py-space bg-light">
        <div className="custom-container">
          <Row className="row">
            <Col
              xl="4"
              lg="6"
              md="8"
              style={{ background: "#191919" }}
              className="offset-xl-4 offset-lg-3 offset-md-2"
            >
              <div className="theme-card">
                <h3 className="text-center">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Login",
                        "lbl_login_title"
                      )
                    : "Login"}
                </h3>
                <form className="theme-form" onSubmit={submitLoginForm}>
                  <div className="form-group">
                    <Label htmlFor="phone">
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Mobile No",
                            "lbl_login_email"
                          )
                        : "Mobile No"}
                    </Label>
                    <Input
                      type="tel"
                      className="form-control"
                      placeholder="Enter your mobile number"
                      id="phone"
                      name="phone"
                      required={true}
                      value={Phone}
                      onChange={(e) => setPhone(e.target.value)}
                      maxLength="10"
                    />
                  </div>

                  <Button
                    type="submit"
                    className="btn btn-normal updated"
                    id="lbl_login_loginbtn"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "Loading..."
                      : LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Login",
                          "lbl_login_loginbtn"
                        )
                      : "Login"}
                  </Button>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Login;
