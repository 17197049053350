 const CityList = [
  { id: 1, name: "Adampan" },
{ id: 2, name: "Addalaichenai" },
{ id: 3, name: "Adippala" },
{ id: 4, name: "Agalawatta" },
{ id: 5, name: "Agaliya" },
{ id: 6, name: "Agbopura" },
{ id: 7, name: "Ahangama" },
{ id: 8, name: "Ahungalla" },
{ id: 9, name: "Akaragama" },
{ id: 10, name: "Akarawita" },
{ id: 11, name: "Akarella" },
{ id: 12, name: "Akkaraipattu" },
{ id: 13, name: "Akkarasiyaya" },
{ id: 14, name: "Akmeemana" },
{ id: 15, name: "akurana" },
{ id: 16, name: "Akuressa" },
{ id: 17, name: "Alapaladeniya" },
{ id: 18, name: "Alawala" },
{ id: 19, name: "Alawatugoda" },
{ id: 20, name: "Alawatura" },
{ id: 21, name: "Algama (Kegalle)" },
{ id: 22, name: "Alubomulla" },
{ id: 23, name: "Aludeniya" },
{ id: 24, name: "Aluketiyawa" },
{ id: 25, name: "Alutgama" },
{ id: 26, name: "Aluthgama (  Kalutara )" },
{ id: 27, name: "aluthgama ( Galle )" },
{ id: 28, name: "Aluthwala" },
{ id: 29, name: "Alutnuwara" },
{ id: 30, name: "Aluttaramma" },
{ id: 31, name: "Ambadandegama" },
{ id: 32, name: "Ambagahapelessa" },
{ id: 33, name: "Ambagahawatta" },
{ id: 34, name: "Ambagasdowa" },
{ id: 35, name: "Ambagaspitiya" },
{ id: 36, name: "Ambakandawila" },
{ id: 37, name: "Ambalakanda" },
{ id: 38, name: "Ambalangoda" },
{ id: 39, name: "Ambalantota" },
{ id: 40, name: "Ambatenna" },
{ id: 41, name: "Ambepussa" },
{ id: 42, name: "Ambulugala" },
{ id: 43, name: "Amitirigala" },
{ id: 44, name: "Ampagala" },
{ id: 45, name: "ampara" },
{ id: 46, name: "Ampegama" },
{ id: 47, name: "Ampilanthurai" },
{ id: 48, name: "Ampitiya" },
{ id: 49, name: "Amugoda" },
{ id: 50, name: "Amunumulla" },
{ id: 51, name: "Anaikottai" },
{ id: 52, name: "Analaitivu" },
{ id: 53, name: "Anamaduwa" },
{ id: 54, name: "Anangoda" },
{ id: 55, name: "Andiambalama" },
{ id: 56, name: "Andigama" },
{ id: 57, name: "Andiyagala" },
{ id: 58, name: "Angamuwa" },
{ id: 59, name: "Angoda" },
{ id: 60, name: "angulana" },
{ id: 61, name: "Angulugaha" },
{ id: 62, name: "Angunakolapelessa" },
{ id: 63, name: "Angunawila" },
{ id: 64, name: "Anguruwatota" },
{ id: 65, name: "Anhettigama" },
{ id: 66, name: "Ankokkawala" },
{ id: 67, name: "Ankumbura" },
{ id: 68, name: "Anuradhapura" },
{ id: 69, name: "Aparekka" },
{ id: 70, name: "Araipattai" },
{ id: 71, name: "Aranayaka" },
{ id: 72, name: "Arangala" },
{ id: 73, name: "Aranthalawa" },
{ id: 74, name: "Arawa" },
{ id: 75, name: "Arawakumbura" },
{ id: 76, name: "Arawatta" },
{ id: 77, name: "Aruggammana" },
{ id: 78, name: "Atabage" },
{ id: 79, name: "Atakalanpanna" },
{ id: 80, name: "Atakiriya" },
{ id: 81, name: "Atale" },
{ id: 82, name: "Atchuveli" },
{ id: 83, name: "Athuraliya" },
{ id: 84, name: "Athurugiriya" },
{ id: 85, name: "Attampitiya" },
{ id: 86, name: "Attanagalla" },
{ id: 87, name: "Attawilluwa" },
{ id: 88, name: "Attidiya" },
{ id: 89, name: "Avissawella" },
{ id: 90, name: "Awukana" },
{ id: 91, name: "Ayagama" },
{ id: 92, name: "Ayithiyamalai" },
{ id: 93, name: "Badalgama" },
{ id: 94, name: "Baddegama" },
{ id: 95, name: "Badulla" },
{ id: 96, name: "Baduluoya" },
{ id: 97, name: "Baduraliya" },
{ id: 98, name: "Bakkiella" },
{ id: 99, name: "Bakmitiyawa" },
{ id: 100, name: "Balana" },
{ id: 101, name: "Balangoda" },
{ id: 102, name: "Balapitiya" },
{ id: 103, name: "Ballaketuwa" },
{ id: 104, name: "Bambaragahaela" },
{ id: 105, name: "Bambarapana" },
{ id: 106, name: "Banagala" },
{ id: 107, name: "Bandagiriya Colony" },
{ id: 108, name: "Bandaragama" },
{ id: 109, name: "bandarawela" },
{ id: 110, name: "Banduragoda" },
{ id: 111, name: "Bangadeniya" },
{ id: 112, name: "Baranankattuwa" },
{ id: 113, name: "Barawakumbuka" },
{ id: 114, name: "Barawardhana Oya" },
{ id: 115, name: "Batagolladeniya" },
{ id: 116, name: "Batapola" },
{ id: 117, name: "Batatota" },
{ id: 118, name: "Battaramulla" },
{ id: 119, name: "Batticaloa" },
{ id: 120, name: "Battuluoya" },
{ id: 121, name: "Batugampola" },
{ id: 122, name: "Batugoda" },
{ id: 123, name: "Batumulla" },
{ id: 124, name: "Batuwatta" },
{ id: 125, name: "Batuwita" },
{ id: 126, name: "Bawlana" },
{ id: 127, name: "Baykiella" },
{ id: 128, name: "BBB" },
{ id: 129, name: "Beliatta" },
{ id: 130, name: "Beligala(sab)" },
{ id: 131, name: "Belihuloya" },
{ id: 132, name: "Bellana" },
{ id: 133, name: "Bemmulla" },
{ id: 134, name: "Bengamuwa" },
{ id: 135, name: "Benthota" },
{ id: 136, name: "Bentota" },
{ id: 137, name: "Beragama" },
{ id: 138, name: "Beralapanathara" },
{ id: 139, name: "Beralihela" },
{ id: 140, name: "Beramada" },
{ id: 141, name: "Berannawa" },
{ id: 142, name: "Beruwala" },
{ id: 143, name: "bibile (badulla)" },
{ id: 144, name: "Bibilegama" },
{ id: 145, name: "Biyagama" },
{ id: 146, name: "Biyagama Ipz" },
{ id: 147, name: "Biyagama Town" },
{ id: 148, name: "Bogahakumbura" },
{ id: 149, name: "Bogahawewa" },
{ id: 150, name: "Bokalagama" },
{ id: 151, name: "bokundara" },
{ id: 152, name: "Bollete (wp)" },
{ id: 153, name: "Bolossagama" },
{ id: 154, name: "Bolthumbe" },
{ id: 155, name: "Bombuwala" },
{ id: 156, name: "Bomluwageaina" },
{ id: 157, name: "Boossa" },
{ id: 158, name: "Bopagama" },
{ id: 159, name: "Bopagoda" },
{ id: 160, name: "Bopana" },
{ id: 161, name: "Bope" },
{ id: 162, name: "Bopitiya (Kegalle)" },
{ id: 163, name: "Boragas" },
{ id: 164, name: "Boralanda" },
{ id: 165, name: "Boralankada" },
{ id: 166, name: "Boralesgamuwa" },
{ id: 167, name: "Boralugoda" },
{ id: 168, name: "Borapola" },
{ id: 169, name: "Bossella" },
{ id: 170, name: "Bowalagama" },
{ id: 171, name: "Bowela" },
{ id: 172, name: "Buckmigama" },
{ id: 173, name: "Bujjampola" },
{ id: 174, name: "Bulathkohupitiya" },
{ id: 175, name: "Bulathsinhala" },
{ id: 176, name: "Bulutota" },
{ id: 177, name: "Bundala" },
{ id: 178, name: "Buthpitiya" },
{ id: 179, name: "buttala" },
{ id: 180, name: "Central Camp" },
{ id: 181, name: "Chavakachcheri" },
{ id: 182, name: "Cheddipalayam" },
{ id: 183, name: "Chenkaladi" },
{ id: 184, name: "Chilaw" },
{ id: 185, name: "Chinabay" },
{ id: 186, name: "Chunnakam" },
{ id: 187, name: "Colombo 01" },
{ id: 188, name: "Colombo 02" },
{ id: 189, name: "Colombo 03" },
{ id: 190, name: "Colombo 04" },
{ id: 191, name: "Colombo 05" },
{ id: 192, name: "Colombo 06" },
{ id: 193, name: "Colombo 07" },
{ id: 194, name: "Colombo 08" },
{ id: 195, name: "Colombo 09" },
{ id: 196, name: "Colombo 10" },
{ id: 197, name: "Colombo 11" },
{ id: 198, name: "Colombo 12" },
{ id: 199, name: "Colombo 13" },
{ id: 200, name: "Colombo 14" },
{ id: 201, name: "Colombo 15" },
{ id: 202, name: "Dadayamtalawa" },
{ id: 203, name: "Dagonna" },
{ id: 204, name: "Damana" },
{ id: 205, name: "Damanewela" },
{ id: 206, name: "Dambadeniya" },
{ id: 207, name: "Dambana" },
{ id: 208, name: "dambulla" },
{ id: 209, name: "Dambuluwana" },
{ id: 210, name: "Dampahala" },
{ id: 211, name: "Damunupola" },
{ id: 212, name: "Danawala Thiniyawala" },
{ id: 213, name: "Dankotuwa" },
{ id: 214, name: "Danowita" },
{ id: 215, name: "Danture" },
{ id: 216, name: "Darga Town" },
{ id: 217, name: "Daugala" },
{ id: 218, name: "Debahera" },
{ id: 219, name: "Debathgama" },
{ id: 220, name: "Dedigamuwa" },
{ id: 221, name: "Dedugala" },
{ id: 222, name: "Dedunupitiya" },
{ id: 223, name: "Deegala Lenama" },
{ id: 224, name: "Deegawapiya" },
{ id: 225, name: "Deewala Pallegama" },
{ id: 226, name: "Dehiattakandiya" },
{ id: 227, name: "Dehiowita" },
{ id: 228, name: "Dehiwala" },
{ id: 229, name: "Dehiwatte" },
{ id: 230, name: "Deiyandara" },
{ id: 231, name: "Dekatana" },
{ id: 232, name: "Dekinda" },
{ id: 233, name: "Dela" },
{ id: 234, name: "Deldeniya" },
{ id: 235, name: "Delf Island" },
{ id: 236, name: "Delgoda" },
{ id: 237, name: "Delkanda" },
{ id: 238, name: "Dellawa" },
{ id: 239, name: "Delmella" },
{ id: 240, name: "Deloluwa" },
{ id: 241, name: "Deltara" },
{ id: 242, name: "Deltota" },
{ id: 243, name: "Delwagura" },
{ id: 244, name: "Delwala" },
{ id: 245, name: "Demalagama" },
{ id: 246, name: "Demanhandiya" },
{ id: 247, name: "Dematawewa" },
{ id: 248, name: "Demodara" },
{ id: 249, name: "Demuwatha" },
{ id: 250, name: "Denagama" },
{ id: 251, name: "Denipitiya" },
{ id: 252, name: "Deniyaya" },
{ id: 253, name: "Derangala" },
{ id: 254, name: "deraniyagala" },
{ id: 255, name: "Devalahinda" },
{ id: 256, name: "Dewalapola" },
{ id: 257, name: "Dewalegama" },
{ id: 258, name: "Dewanagala" },
{ id: 259, name: "Digamadulla Weeragoda" },
{ id: 260, name: "Diganatenna" },
{ id: 261, name: "Dikkapitiya" },
{ id: 262, name: "Dikkumbura" },
{ id: 263, name: "Dikwella" },
{ id: 264, name: "Dimbulana" },
{ id: 265, name: "Divulapelessa" },
{ id: 266, name: "Divulapitiya" },
{ id: 267, name: "Divuldeniya" },
{ id: 268, name: "Diwalakada" },
{ id: 269, name: "Diyagaha" },
{ id: 270, name: "Diyalape" },
{ id: 271, name: "diyatalawa" },
{ id: 272, name: "Dodampe" },
{ id: 273, name: "Dodanduwa" },
{ id: 274, name: "Dodangoda" },
{ id: 275, name: "Dolapihilla" },
{ id: 276, name: "Dolosbage" },
{ id: 277, name: "Doloswalakanda" },
{ id: 278, name: "Doluwa" },
{ id: 279, name: "Dombagoda" },
{ id: 280, name: "Dombemada" },
{ id: 281, name: "Dompe" },
{ id: 282, name: "Doragamuwa" },
{ id: 283, name: "Dorakumbura" },
{ id: 284, name: "Dorawaka" },
{ id: 285, name: "Dulgolla" },
{ id: 286, name: "Dumbara Manana" },
{ id: 287, name: "Dunagaha" },
{ id: 288, name: "Dunkannawa" },
{ id: 289, name: "Dunumadalawa" },
{ id: 290, name: "Dunumala" },
{ id: 291, name: "Dunuwila" },
{ id: 292, name: "Dutuwewa" },
{ id: 293, name: "Echchilampattai" },
{ id: 294, name: "Egodawela" },
{ id: 295, name: "Eheliyagoda" },
{ id: 296, name: "Ekala" },
{ id: 297, name: "Ekiriya" },
{ id: 298, name: "Elamulla" },
{ id: 299, name: "Elapatha" },
{ id: 300, name: "Elayapattuwa" },
{ id: 301, name: "Ella" },
{ id: 302, name: "Ella Tanabaddegama" },
{ id: 303, name: "Ellagala" },
{ id: 304, name: "Ellagawa" },
{ id: 305, name: "Ellakkala" },
{ id: 306, name: "Ellaulla" },
{ id: 307, name: "Ellawala" },
{ id: 308, name: "Elpitiya" },
{ id: 309, name: "Eluwankulama" },
{ id: 310, name: "Embilipitiya" },
{ id: 311, name: "embilipitiya new town" },
{ id: 312, name: "Embuldeniya" },
{ id: 313, name: "Eppawala" },
{ id: 314, name: "Eratna" },
{ id: 315, name: "Eravur" },
{ id: 316, name: "Erepola" },
{ id: 317, name: "Essella" },
{ id: 318, name: "Etawatunuwewa" },
{ id: 319, name: "Etaweeragollewa" },
{ id: 320, name: "Ethkandura" },
{ id: 321, name: "Ettale" },
{ id: 322, name: "Etulgama" },
{ id: 323, name: "Gabbela" },
{ id: 324, name: "Galaboda" },
{ id: 325, name: "Galadivulwewa" },
{ id: 326, name: "Galagedara (Kandy)" },
{ id: 327, name: "Galaha" },
{ id: 328, name: "Galapitagala" },
{ id: 329, name: "Galapitamada" },
{ id: 330, name: "Galatara" },
{ id: 331, name: "Galauda" },
{ id: 332, name: "Galedanda" },
{ id: 333, name: "Galenbindunuwewa" },
{ id: 334, name: "Galhinna" },
{ id: 335, name: "Galigamuwa Town" },
{ id: 336, name: "Galkadawala" },
{ id: 337, name: "Galkiriyagama" },
{ id: 338, name: "Galkulama" },
{ id: 339, name: "Galle" },
{ id: 340, name: "Gallella" },
{ id: 341, name: "Gallellagama" },
{ id: 342, name: "galmaththa" },
{ id: 343, name: "Galmetiyawa" },
{ id: 344, name: "Galmuruwa" },
{ id: 345, name: "Galnewa" },
{ id: 346, name: "Galpatha (Kalutara)" },
{ id: 347, name: "Galpatha(Kegalle)" },
{ id: 348, name: "Galporuyaya" },
{ id: 349, name: "Gamagoda" },
{ id: 350, name: "Gambirigaswewa" },
{ id: 351, name: "Gamewela" },
{ id: 352, name: "Gampaha" },
{ id: 353, name: "Gampola" },
{ id: 354, name: "Gandara" },
{ id: 355, name: "Ganegoda" },
{ id: 356, name: "Ganemulla" },
{ id: 357, name: "Ganewalpola" },
{ id: 358, name: "Gangeyaya" },
{ id: 359, name: "Gangodawila" },
{ id: 360, name: "Gangulandeniya" },
{ id: 361, name: "Ganthuna" },
{ id: 362, name: "Gantuna" },
{ id: 363, name: "Gatahetta" },
{ id: 364, name: "Gawaragiriya" },
{ id: 365, name: "Gawarawela" },
{ id: 366, name: "Gelioya" },
{ id: 367, name: "Gemunupura" },
{ id: 368, name: "Getahetta" },
{ id: 369, name: "Getalawa" },
{ id: 370, name: "Getamanna" },
{ id: 371, name: "Gillimale" },
{ id: 372, name: "Ginimellagaha" },
{ id: 373, name: "Gintota" },
{ id: 374, name: "Girandurukotte" },
{ id: 375, name: "Gnanikulama" },
{ id: 376, name: "Goda Koggalla" },
{ id: 377, name: "Godagampola" },
{ id: 378, name: "Godahena" },
{ id: 379, name: "Godakawela" },
{ id: 380, name: "Godamunna" },
{ id: 381, name: "Godapitiya" },
{ id: 382, name: "Godunna" },
{ id: 383, name: "Gomagoda" },
{ id: 384, name: "Gomarankadawala" },
{ id: 385, name: "Gomilamawarala" },
{ id: 386, name: "Gonagala" },
{ id: 387, name: "Gonagalpura" },
{ id: 388, name: "Gonagamuwa Uduwila" },
{ id: 389, name: "Gonagantenna" },
{ id: 390, name: "Gonagolla" },
{ id: 391, name: "Gonahaddenawa" },
{ id: 392, name: "Gonamulla Junction" },
{ id: 393, name: "Gonapinuwala" },
{ id: 394, name: "Gonapola junction" },
{ id: 395, name: "Gonawalapatana" },
{ id: 396, name: "Gonawalawp" },
{ id: 397, name: "Gonawila" },
{ id: 398, name: "Gongodawila" },
{ id: 399, name: "Gonnoruwa" },
{ id: 400, name: "gothatuwa" },
{ id: 401, name: "Govinna" },
{ id: 402, name: "Gunnepana" },
{ id: 403, name: "Gurubewilagama" },
{ id: 404, name: "Gurudeniya" },
{ id: 405, name: "Gurulubadda" },
{ id: 406, name: "Gurutalawa" },
{ id: 407, name: "Habaraduwa" },
{ id: 408, name: "Habarakada" },
{ id: 409, name: "Habarana" },
{ id: 410, name: "Haburugala" },
{ id: 411, name: "Hakahinna" },
{ id: 412, name: "Hakbellawaka" },
{ id: 413, name: "Hakmana" },
{ id: 414, name: "Hakuruwela" },
{ id: 415, name: "Haldummulla" },
{ id: 416, name: "hali ela" },
{ id: 417, name: "Halkandawila" },
{ id: 418, name: "Halloluwa" },
{ id: 419, name: "Halmillawetiya" },
{ id: 420, name: "Halpe balangoda" },
{ id: 421, name: "Haltota" },
{ id: 422, name: "Halvitigala Colony" },
{ id: 423, name: "Halwala" },
{ id: 424, name: "Halwatura" },
{ id: 425, name: "Halwinna" },
{ id: 426, name: "Hambantota" },
{ id: 427, name: "Handaganawa" },
{ id: 428, name: "Handagiriya" },
{ id: 429, name: "Handapangoda" },
{ id: 430, name: "Handawalapitiya" },
{ id: 431, name: "Handessa" },
{ id: 432, name: "Handugala" },
{ id: 433, name: "Hangunnawa" },
{ id: 434, name: "Hanguranketha" },
{ id: 435, name: "Hanwella" },
{ id: 436, name: "Hapugastenna" },
{ id: 437, name: "Haputale" },
{ id: 438, name: "Harankahawa" },
{ id: 439, name: "Harasgala" },
{ id: 440, name: "Hasalaka" },
{ id: 441, name: "Hatangala" },
{ id: 442, name: "Hatarabage" },
{ id: 443, name: "Hataraliyadda" },
{ id: 444, name: "Hawpe" },
{ id: 445, name: "Hebarawa" },
{ id: 446, name: "Hedigalla Colony" },
{ id: 447, name: "Heeloya" },
{ id: 448, name: "Heiyanthuduwa" },
{ id: 449, name: "Helahalpe" },
{ id: 450, name: "Helamada" },
{ id: 451, name: "Helapupula" },
{ id: 452, name: "Hemmathagama" },
{ id: 453, name: "Henegama" },
{ id: 454, name: "Hettimulla" },
{ id: 455, name: "hettipola (Kurunegala)" },
{ id: 456, name: "Hewadiwela" },
{ id: 457, name: "Hewaheta" },
{ id: 458, name: "Hewainna" },
{ id: 459, name: "Hewanakumbura" },
{ id: 460, name: "Hidellana" },
{ id: 461, name: "Hidogama" },
{ id: 462, name: "Hikkaduwa" },
{ id: 463, name: "Hinatiyana Madawala" },
{ id: 464, name: "Hindagala" },
{ id: 465, name: "Hingula" },
{ id: 466, name: "Hinguralakanda" },
{ id: 467, name: "Hingurana" },
{ id: 468, name: "Hingurukaduwa" },
{ id: 469, name: "Hiniduma" },
{ id: 470, name: "Hiramadagama" },
{ id: 471, name: "Hiripitiya (Homagama )" },
{ id: 472, name: "Hiripitiya (kottawa)" },
{ id: 473, name: "Hiriwadunna" },
{ id: 474, name: "Hiswella" },
{ id: 475, name: "Hiyare" },
{ id: 476, name: "Hokandara" },
{ id: 477, name: "Homagama" },
{ id: 478, name: "Hondiyadeniya" },
{ id: 479, name: "Hopton" },
{ id: 480, name: "Horampella" },
{ id: 481, name: "Horana" },
{ id: 482, name: "Horapawita" },
{ id: 483, name: "Horawpatana" },
{ id: 484, name: "Horewelagoda" },
{ id: 485, name: "Horiwila" },
{ id: 486, name: "Hulannuge" },
{ id: 487, name: "Hungama" },
{ id: 488, name: "Hunnasgiriya" },
{ id: 489, name: "Hunumulla" },
{ id: 490, name: "Hurigaswewa" },
{ id: 491, name: "Hurulunikawewa" },
{ id: 492, name: "Idalgashinna" },
{ id: 493, name: "Ihala Beligalla" },
{ id: 494, name: "Ihala Halmillewa" },
{ id: 495, name: "Ihala Kobbekaduwa" },
{ id: 496, name: "Ihala Kottaramulla" },
{ id: 497, name: "Ihala Madampella" },
{ id: 499, name: "Ihala Puliyankulama" },
{ id: 500, name: "Ihala Walpola" },
{ id: 501, name: "Ihalagama" },
{ id: 502, name: "Ihalahewessa" },
{ id: 503, name: "Ilippadeniya" },
{ id: 504, name: "Iluppakadawai" },
{ id: 505, name: "imaduwa" },
{ id: 506, name: "Imbulana" },
{ id: 507, name: "Imbulgasdeniya" },
{ id: 508, name: "Imbulgoda" },
{ id: 509, name: "Induruwa" },
{ id: 510, name: "Inginimitiya" },
{ id: 511, name: "Ingiriya" },
{ id: 512, name: "Ipologama" },
{ id: 513, name: "Iriyagolla" },
{ id: 514, name: "Irukalmpity" },
{ id: 515, name: "Ismailpuram" },
{ id: 516, name: "Iththademaliya" },
{ id: 517, name: "Ittakanda" },
{ id: 518, name: "Ittapana" },
{ id: 519, name: "Ja-ela" },
{ id: 520, name: "Jaffna" },
{ id: 521, name: "jalthara ( Hanwella )" },
{ id: 522, name: "Jambugahapitiya" },
{ id: 523, name: "Jangulla" },
{ id: 524, name: "Julampitiya" },
{ id: 525, name: "Kabagamuwa" },
{ id: 526, name: "Kadaiadampan" },
{ id: 527, name: "Kadawatha" },
{ id: 528, name: "Kaddaiparichchan" },
{ id: 529, name: "Kadugannawa" },
{ id: 530, name: "Kaduwela" },
{ id: 531, name: "Kagama" },
{ id: 532, name: "Kahaduwa" },
{ id: 533, name: "Kahandamodara" },
{ id: 534, name: "Kahangama" },
{ id: 535, name: "Kahatagasdigiliya" },
{ id: 536, name: "Kahatagollewa" },
{ id: 537, name: "Kahataliyadda" },
{ id: 538, name: "Kahataruppa" },
{ id: 539, name: "Kahatowita" },
{ id: 540, name: "Kahawa" },
{ id: 541, name: "Kahawala" },
{ id: 542, name: "Kahawatta" },
{ id: 543, name: "Kaithady" },
{ id: 544, name: "Kakkapalliya" },
{ id: 545, name: "Kalagedihena" },
{ id: 546, name: "Kalakarambewa" },
{ id: 547, name: "Kalankuttiya" },
{ id: 548, name: "Kalaoya" },
{ id: 549, name: "Kalatuwawa" },
{ id: 550, name: "Kalawana" },
{ id: 551, name: "Kalawedi Ulpotha" },
{ id: 552, name: "Kalawila Kiranthidiya" },
{ id: 553, name: "Kaleliya" },
{ id: 554, name: "Kalkudah" },
{ id: 555, name: "Kalladiya" },
{ id: 556, name: "Kallanchiya" },
{ id: 557, name: "Kallar" },
{ id: 558, name: "Kalliady" },
{ id: 559, name: "Kalmunai" },
{ id: 560, name: "Kalpitiya" },
{ id: 561, name: "Kaltota" },
{ id: 562, name: "Kaluaggala" },
{ id: 563, name: "Kalubowila" },
{ id: 564, name: "Kalubowitiyana" },
{ id: 565, name: "Kalubululanda" },
{ id: 566, name: "Kalugahakandura" },
{ id: 567, name: "Kalugala" },
{ id: 568, name: "Kalupahana" },
{ id: 569, name: "Kalutara" },
{ id: 570, name: "kalutara south" },
{ id: 571, name: "Kaluwanchikudi" },
{ id: 572, name: "Kaluwankemy" },
{ id: 573, name: "Kamburugamuwa" },
{ id: 574, name: "Kamburupitiya" },
{ id: 575, name: "Kananke Bazaar" },
{ id: 576, name: "Kananwila" },
{ id: 577, name: "Kandaketiya" },
{ id: 578, name: "Kandakuliya" },
{ id: 579, name: "Kandana" },
{ id: 580, name: "Kandanagama" },
{ id: 581, name: "Kandegedara" },
{ id: 582, name: "Kandepuhulpola" },
{ id: 583, name: "Kandy" },
{ id: 584, name: "Kannakipuram" },
{ id: 585, name: "Kannankudah" },
{ id: 586, name: "Kannattota" },
{ id: 587, name: "Kanniya" },
{ id: 588, name: "Kantalai" },
{ id: 589, name: "Kantalai Sugar Factory" },
{ id: 590, name: "Kapugoda" },
{ id: 591, name: "Kapuliyadde" },
{ id: 592, name: "Karadiyanaru" },
{ id: 593, name: "Karagahawewa" },
{ id: 594, name: "karagampitiya" },
{ id: 595, name: "Karagoda" },
{ id: 596, name: "Karagoda Uyangoda" },
{ id: 597, name: "Karainagar" },
{ id: 598, name: "Karandagolla" },
{ id: 599, name: "Karandana" },
{ id: 600, name: "Karandeniya" },
{ id: 601, name: "Karangoda" },
{ id: 602, name: "Karaputugala" },
{ id: 603, name: "Karativponparappi" },
{ id: 604, name: "Karativu" },
{ id: 605, name: "Karatota" },
{ id: 606, name: "Karawitagara" },
{ id: 607, name: "Karisal" },
{ id: 608, name: "Kariyamaditta" },
{ id: 609, name: "Karuwalagaswewa" },
{ id: 610, name: "Katana" },
{ id: 611, name: "Kathiraveli" },
{ id: 612, name: "Kattankudi" },
{ id: 613, name: "Katugastota" },
{ id: 614, name: "Katunayake" },
{ id: 615, name: "Katunayake Air Force Camp" },
{ id: 616, name: "Katunayake(ftz)" },
{ id: 617, name: "Katuneriya" },
{ id: 618, name: "Katuwana" },
{ id: 619, name: "Katuwellegama" },
{ id: 620, name: "kayts" },
{ id: 621, name: "Kebillawela" },
{ id: 622, name: "Kebithigollewa" },
{ id: 623, name: "Kegalle" },
{ id: 624, name: "Kehelpannala" },
{ id: 625, name: "Kehelwatta" },
{ id: 626, name: "Kekanadurra" },
{ id: 627, name: "Kekirawa" },
{ id: 628, name: "Kekirihena" },
{ id: 629, name: "Kelaniya" },
{ id: 630, name: "Kelinkanda" },
{ id: 631, name: "Kella Junction" },
{ id: 632, name: "Kendagolla" },
{ id: 633, name: "Kendewa" },
{ id: 634, name: "kengalla" },
{ id: 635, name: "Keppetipola" },
{ id: 636, name: "Kesbewa" },
{ id: 637, name: "Keselpotha" },
{ id: 638, name: "Ketakumbura" },
{ id: 639, name: "Ketawala Leula" },
{ id: 640, name: "Ketawatta" },
{ id: 641, name: "Kiliveddy" },
{ id: 642, name: "Kimbulapitiya" },
{ id: 643, name: "Kinniya" },
{ id: 644, name: "Kiralogama" },
{ id: 645, name: "Kirama" },
{ id: 646, name: "Kiran" },
{ id: 647, name: "Kirankulam" },
{ id: 648, name: "Kiribathgoda" },
{ id: 649, name: "Kiribathkumbura" },
{ id: 650, name: "Kiriella" },
{ id: 651, name: "Kirigalwewa" },
{ id: 652, name: "Kirinda" },
{ id: 653, name: "Kirindiwela" },
{ id: 654, name: "Kiriwanagama" },
{ id: 655, name: "Kiriwattuduwa" },
{ id: 656, name: "Kiriweldola" },
{ id: 657, name: "Kiriwelkele" },
{ id: 658, name: "Kitalawalana" },
{ id: 659, name: "Kitulgala" },
{ id: 660, name: "Kitulgoda" },
{ id: 661, name: "Kitulhitiyawa" },
{ id: 662, name: "Kitulwala" },
{ id: 663, name: "KKS" },
{ id: 664, name: "Kobonila" },
{ id: 665, name: "Kochchikade" },
{ id: 666, name: "Koddaikallar" },
{ id: 667, name: "Kodikamam" },
{ id: 668, name: "Koholana" },
{ id: 669, name: "Kohuwala" },
{ id: 670, name: "Kokkaddichcholai" },
{ id: 671, name: "Koknahara" },
{ id: 672, name: "Kolabissa" },
{ id: 673, name: "Kolamanthalawa" },
{ id: 674, name: "Kolambageara" },
{ id: 675, name: "Kolawenigama" },
{ id: 676, name: "Kolombugama" },
{ id: 677, name: "Kolongoda" },
{ id: 678, name: "Kolonna" },
{ id: 679, name: "Kolonnawa" },
{ id: 680, name: "Komari" },
{ id: 681, name: "Kondavil" },
{ id: 682, name: "Kondeniya" },
{ id: 683, name: "kopay" },
{ id: 684, name: "Kosgama" },
{ id: 685, name: "Kosgoda" },
{ id: 686, name: "Koslanda" },
{ id: 687, name: "Kotadeniyawa" },
{ id: 688, name: "Kotamuduna" },
{ id: 689, name: "Kotapola" },
{ id: 690, name: "Kotawehera Mankada" },
{ id: 691, name: "Kotikawatta" },
{ id: 692, name: "Kotiyakumbura" },
{ id: 693, name: "Kottantivu" },
{ id: 694, name: "Kottawa" },
{ id: 695, name: "Kottawagama" },
{ id: 696, name: "Kottegoda" },
{ id: 697, name: "Kottukachchiya" },
{ id: 698, name: "Kotugoda" },
{ id: 699, name: "Kuchchaveli" },
{ id: 700, name: "Kuda Uduwa" },
{ id: 701, name: "Kudawa" },
{ id: 702, name: "Kudawewa" },
{ id: 703, name: "Kuleegoda" },
{ id: 704, name: "kuliyapitiya" },
{ id: 705, name: "Kulugammana" },
{ id: 706, name: "Kumarakattuwa" },
{ id: 707, name: "Kumbaloluwa" },
{ id: 708, name: "Kumbukkandura" },
{ id: 709, name: "Kumburegama" },
{ id: 710, name: "Kumburupiddy" },
{ id: 711, name: "Kundasale" },
{ id: 712, name: "Kuratihena" },
{ id: 713, name: "Kurinchakemy" },
{ id: 714, name: "Kurinjanpitiya" },
{ id: 715, name: "Kuruketiyawa" },
{ id: 716, name: "Kurukkalmadam" },
{ id: 717, name: "Kurundankulama" },
{ id: 718, name: "kurunegala" },
{ id: 719, name: "Kuruwita" },
{ id: 720, name: "Kuruwitenna" },
{ id: 721, name: "Kuttiyagolla" },
{ id: 722, name: "Labunoruwa" },
{ id: 723, name: "Lahugala" },
{ id: 724, name: "Landewela" },
{ id: 725, name: "Lankagama" },
{ id: 726, name: "Lankapatuna" },
{ id: 727, name: "Leemagahakotuwa" },
{ id: 728, name: "Lellopitiya" },
{ id: 729, name: "Lewangama" },
{ id: 730, name: "Lihiriyagama" },
{ id: 731, name: "Liyangahawela" },
{ id: 732, name: "lllagolla" },
{ id: 733, name: "Lmbulpe" },
{ id: 734, name: "lmkkamam" },
{ id: 735, name: "Loluwagoda" },
{ id: 736, name: "Lunama" },
{ id: 737, name: "lunugala" },
{ id: 738, name: "Lunugama" },
{ id: 739, name: "Lunugamwehera" },
{ id: 740, name: "Lunuketiya Maditta" },
{ id: 741, name: "Lunuwatta" },
{ id: 742, name: "Lunuwila" },
{ id: 743, name: "Mabodale" },
{ id: 744, name: "Madalagama" },
{ id: 745, name: "Madampe(Puttalam)" },
{ id: 746, name: "Madapatha" },
{ id: 747, name: "Madatugama" },
{ id: 748, name: "Madawala Bazaar" },
{ id: 749, name: "Madawalalanda" },
{ id: 750, name: "Madelgamuwa" },
{ id: 751, name: "Madugalla" },
{ id: 752, name: "Madulkele" },
{ id: 753, name: "Madulsima" },
{ id: 754, name: "Madurankuliya" },
{ id: 755, name: "Magama" },
{ id: 756, name: "Magedara" },
{ id: 757, name: "Maggona" },
{ id: 758, name: "Maha Elagamuwa" },
{ id: 759, name: "Mahabage" },
{ id: 760, name: "Mahabulankulama" },
{ id: 761, name: "Mahadivulwewa" },
{ id: 762, name: "Mahadoraliyadda" },
{ id: 763, name: "Mahagalwewa" },
{ id: 764, name: "Mahagama" },
{ id: 765, name: "Mahailluppallama" },
{ id: 766, name: "Mahakalupahana" },
{ id: 767, name: "Mahakumbukkadawala" },
{ id: 768, name: "Mahamedagama" },
{ id: 769, name: "Mahanagapura" },
{ id: 770, name: "mahaoya" },
{ id: 771, name: "Mahapallegama" },
{ id: 772, name: "Mahapothana" },
{ id: 773, name: "Maharagama" },
{ id: 774, name: "Maharangalla" },
{ id: 775, name: "Maharugiramam" },
{ id: 776, name: "Mahasenpura (Anuradhapura)" },
{ id: 777, name: "Mahauswewa" },
{ id: 778, name: "Mahawalatenna" },
{ id: 779, name: "Mahawewa" },
{ id: 780, name: "Mahawilachchiya" },
{ id: 781, name: "mahiyanganaya" },
{ id: 782, name: "Mailagaswewa" },
{ id: 783, name: "Mailapitiya" },
{ id: 784, name: "Makandura (Matara)" },
{ id: 785, name: "Makandura (Ratnapura)" },
{ id: 786, name: "Makehelwala" },
{ id: 787, name: "Makewita" },
{ id: 788, name: "Makkanigama" },
{ id: 789, name: "Makola" },
{ id: 790, name: "Makuldeniya" },
{ id: 791, name: "Makulella" },
{ id: 792, name: "Malabe" },
{ id: 793, name: "Malalpola" },
{ id: 794, name: "Malgalla Talangalla" },
{ id: 795, name: "Malgoda" },
{ id: 796, name: "Maliboda" },
{ id: 797, name: "Maliduwa" },
{ id: 798, name: "Maliyadda" },
{ id: 799, name: "Mallikativu" },
{ id: 800, name: "Malmaduwa" },
{ id: 801, name: "Malwala Junction" },
{ id: 802, name: "Malwana" },
{ id: 803, name: "Malwanagama" },
{ id: 804, name: "Malwatta" },
{ id: 805, name: "Mamadala" },
{ id: 806, name: "mamangam" },
{ id: 807, name: "Mampuri" },
{ id: 808, name: "Mandaram Nuwara" },
{ id: 809, name: "Mandawala" },
{ id: 810, name: "Mandur" },
{ id: 811, name: "Maneruwa" },
{ id: 812, name: "Mangalaeliya" },
{ id: 813, name: "Mangalagama" },
{ id: 814, name: "manipay" },
{ id: 815, name: "Mankerni" },
{ id: 816, name: "mannar" },
{ id: 817, name: "Mapakadawewa" },
{ id: 818, name: "Mapakanda" },
{ id: 819, name: "Mapalagama" },
{ id: 820, name: "Mapalagama Central" },
{ id: 821, name: "Maradankadawala" },
{ id: 822, name: "Maradankalla" },
{ id: 823, name: "Maramba" },
{ id: 824, name: "Marandagahamula" },
{ id: 825, name: "Marapana" },
{ id: 826, name: "Marassana" },
{ id: 827, name: "marathamunai" },
{ id: 828, name: "Marawila" },
{ id: 829, name: "Marymount Colony" },
{ id: 830, name: "maskeliya" },
{ id: 831, name: "Maspanna" },
{ id: 832, name: "Matale" },
{ id: 833, name: "Matara" },
{ id: 834, name: "Mattaka" },
{ id: 835, name: "Matugama" },
{ id: 836, name: "Maturata" },
{ id: 837, name: "Matuwagalagama" },
{ id: 838, name: "Maussagolla" },
{ id: 839, name: "Mawadichenai" },
{ id: 840, name: "Mawanagama" },
{ id: 841, name: "Mawanella" },
{ id: 842, name: "Mawatura" },
{ id: 843, name: "Medagalatur" },
{ id: 844, name: "Meda-keembiya" },
{ id: 845, name: "Medamahanuwara" },
{ id: 846, name: "Medamulana" },
{ id: 847, name: "Medawachchiya" },
{ id: 848, name: "Medawala Harispattuwa" },
{ id: 849, name: "Medawela Udukinda" },
{ id: 850, name: "Medawelagama" },
{ id: 851, name: "Meddekanda" },
{ id: 852, name: "Mediripitiya" },
{ id: 853, name: "Meegahakiula" },
{ id: 854, name: "Meegahatenna" },
{ id: 855, name: "meegakaiula" },
{ id: 856, name: "Meegama" },
{ id: 857, name: "Meegoda" },
{ id: 858, name: "Meemure" },
{ id: 859, name: "Meeravodai" },
{ id: 860, name: "Meetalawa" },
{ id: 861, name: "Meethalawa" },
{ id: 862, name: "Meetiyagoda" },
{ id: 863, name: "Megoda Kalugamuwa" },
{ id: 864, name: "Megodawewa" },
{ id: 865, name: "Mellawagedara" },
{ id: 866, name: "melsiripura" },
{ id: 867, name: "Menikdiwela" },
{ id: 868, name: "Menikhinna" },
{ id: 869, name: "Metigahatenna" },
{ id: 870, name: "Middeniya" },
{ id: 871, name: "Miella" },
{ id: 872, name: "Migahajandura" },
{ id: 873, name: "Migastenna Sabara" },
{ id: 874, name: "Mihintale" },
{ id: 875, name: "Millaniya" },
{ id: 876, name: "Millewa" },
{ id: 877, name: "Mimure" },
{ id: 878, name: "Minigamuwa" },
{ id: 879, name: "Minipe" },
{ id: 880, name: "Minipura Dumbara" },
{ id: 881, name: "Minuwangoda" },
{ id: 882, name: "Mirahawatta" },
{ id: 883, name: "Mirigama" },
{ id: 884, name: "Mirissa" },
{ id: 885, name: "Miriswatta ( galle )" },
{ id: 886, name: "Miriyabedda" },
{ id: 887, name: "Mithirigala" },
{ id: 888, name: "Mitipola" },
{ id: 889, name: "Miwanapalana" },
{ id: 890, name: "Miyanakandura" },
{ id: 891, name: "Miyanawita" },
{ id: 892, name: "Modarawana" },
{ id: 893, name: "Molagoda" },
{ id: 894, name: "Molkawa" },
{ id: 895, name: "monaragala" },
{ id: 896, name: "Moragahapallama" },
{ id: 897, name: "Moragala Kirillapone" },
{ id: 898, name: "Morahela" },
{ id: 899, name: "Morakewa" },
{ id: 900, name: "Morapitiya" },
{ id: 901, name: "Moratuwa" },
{ id: 902, name: "Morawaka" },
{ id: 903, name: "Morontota" },
{ id: 904, name: "Morontuduwa" },
{ id: 905, name: "Mount Lavinia" },
{ id: 906, name: "Mudalakkuliya" },
{ id: 907, name: "Muddaragama" },
{ id: 908, name: "Mudungoda" },
{ id: 909, name: "Mugunuwatawana" },
{ id: 910, name: "Mukkutoduwawa" },
{ id: 911, name: "Mulatiyana Junction" },
{ id: 912, name: "Mulendiyawala" },
{ id: 913, name: "Mulgama" },
{ id: 914, name: "Mulkirigala" },
{ id: 915, name: "Mullativu" },
{ id: 916, name: "Mulleriyawa" },
{ id: 917, name: "Mulleriyawa New Town" },
{ id: 918, name: "Mullipothana" },
{ id: 919, name: "Mundala" },
{ id: 920, name: "Mundel" },
{ id: 921, name: "Murakottanchanai" },
{ id: 922, name: "Muriyakadawala" },
{ id: 923, name: "Murutalawa" },
{ id: 924, name: "Muruthagahamulla" },
{ id: 925, name: "Muruthalawa" },
{ id: 926, name: "Muttibandivila" },
{ id: 927, name: "Mutur" },
{ id: 928, name: "Nachchaduwa" },
{ id: 929, name: "Nadugala" },
{ id: 930, name: "Nagoda (Galle)" },
{ id: 931, name: "Naimana" },
{ id: 932, name: "Nainamadama" },
{ id: 933, name: "Nakiyadeniya" },
{ id: 934, name: "Nakulugamuwa" },
{ id: 935, name: "Nalladarankattuwa" },
{ id: 936, name: "Namaloya" },
{ id: 937, name: "Namalpura" },
{ id: 938, name: "Namunukula" },
{ id: 939, name: "Napawela" },
{ id: 940, name: "Narahempita" },
{ id: 941, name: "Narahenpita" },
{ id: 942, name: "Narangala ( badulla )" },
{ id: 943, name: "Naranpanawa" },
{ id: 944, name: "Naranwala" },
{ id: 945, name: "Narawelpita" },
{ id: 946, name: "Nattandiya" },
{ id: 947, name: "Nattandiya Koswatte" },
{ id: 948, name: "Nattarampotha" },
{ id: 949, name: "Navagirinagar" },
{ id: 950, name: "Navatkadu" },
{ id: 951, name: "Navithanveli" },
{ id: 952, name: "Nawadagala" },
{ id: 953, name: "nawagampura" },
{ id: 954, name: "Nawagattegama" },
{ id: 955, name: "Nawagiriyawa" },
{ id: 956, name: "Nawala" },
{ id: 957, name: "Nawalakanda" },
{ id: 958, name: "Nawalapitiya" },
{ id: 959, name: "Nawamedagama" },
{ id: 960, name: "Nawana" },
{ id: 961, name: "Nawattuduwa" },
{ id: 962, name: "Nawinnapinnakanda" },
{ id: 963, name: "Neboda" },
{ id: 964, name: "Nedungamuwa" },
{ id: 965, name: "Neelapola" },
{ id: 966, name: "Negampaha" },
{ id: 967, name: "Negombo" },
{ id: 968, name: "Nelliady" },
{ id: 969, name: "Nelumdeniya" },
{ id: 970, name: "Nelumgama" },
{ id: 971, name: "Nelundeniya" },
{ id: 972, name: "Neluwa" },
{ id: 973, name: "Netolpitiya" },
{ id: 974, name: "Nihiluwa" },
{ id: 975, name: "Nikahetikanda" },
{ id: 976, name: "Nikapotha" },
{ id: 977, name: "Nikaweratiya" },
{ id: 978, name: "Nilaveli" },
{ id: 979, name: "Nillambe" },
{ id: 980, name: "Nindana" },
{ id: 981, name: "Nintavur" },
{ id: 982, name: "Niralagama" },
{ id: 983, name: "Nittambuwa" },
{ id: 984, name: "Nivitigala" },
{ id: 985, name: "Niwandama" },
{ id: 986, name: "Niyadurupola" },
{ id: 987, name: "Nochchiyagama" },
{ id: 988, name: "Noori" },
{ id: 989, name: "Norachcholai" },
{ id: 990, name: "Nugaliyadda" },
{ id: 991, name: "Nugatalawa" },
{ id: 992, name: "Nugawela" },
{ id: 993, name: "Nugegoda" },
{ id: 994, name: "Nugelanda" },
{ id: 995, name: "Nuwara eliya" },
{ id: 996, name: "Nuwaragalathenna" },
{ id: 997, name: "Oddamavadi" },
{ id: 998, name: "Ohiya" },
{ id: 999, name: "okkampitiya" },
{ id: 1000, name: "Oluvil" },
{ id: 1001, name: "Omalpe" },
{ id: 1002, name: "Opanayaka" },
{ id: 1003, name: "Opatha katugoda" },
{ id: 1004, name: "Padagoda" },
{ id: 1005, name: "Padalangala" },
{ id: 1006, name: "Padavi Maithripura" },
{ id: 1007, name: "Padavi Parakramapura" },
{ id: 1008, name: "Padavi Sripura" },
{ id: 1009, name: "Padavi Sritissapura" },
{ id: 1010, name: "Padaviya" },
{ id: 1011, name: "Padawkema" },
{ id: 1012, name: "Padikaramaduwa" },
{ id: 1013, name: "Padiyatalawa" },
{ id: 1014, name: "Padiyattalawa" },
{ id: 1015, name: "Padukka" },
{ id: 1016, name: "Pahala Andarawewa" },
{ id: 1017, name: "Pahala Halmillewa" },
{ id: 1018, name: "Pahala Maragahawe" },
{ id: 1019, name: "Pahala Millawa" },
{ id: 1020, name: "Pahalagama (Anuradhapura)" },
{ id: 1021, name: "pahalagama (Badulla)" },
{ id: 1022, name: "Pahalahewessa" },
{ id: 1023, name: "Pahalalanda" },
{ id: 1024, name: "Pahalarathkinda" },
{ id: 1025, name: "Paiyagala" },
{ id: 1026, name: "Palagala" },
{ id: 1027, name: "Palali" },
{ id: 1028, name: "Palamunai (Ampara)" },
{ id: 1029, name: "Palathuruwella" },
{ id: 1030, name: "Palatuwa" },
{ id: 1031, name: "Palaviya" },
{ id: 1032, name: "Pallama" },
{ id: 1033, name: "Pallebedda" },
{ id: 1034, name: "Pallebowala" },
{ id: 1035, name: "Pallekanda" },
{ id: 1036, name: "Pallekiruwa" },
{ id: 1037, name: "Pallekotuwa" },
{ id: 1038, name: "Pallewela" },
{ id: 1039, name: "Palliwasalturai" },
{ id: 1040, name: "Palugaswewa (Habarana)" },
{ id: 1041, name: "Pambagolla" },
{ id: 1042, name: "Pamunugama" },
{ id: 1043, name: "Pamunuwatta" },
{ id: 1044, name: "Panadura" },
{ id: 1045, name: "Panagoda (Homagama)" },
{ id: 1046, name: "Panama" },
{ id: 1047, name: "Panamura" },
{ id: 1048, name: "Panangala" },
{ id: 1049, name: "Panapitiya" },
{ id: 1050, name: "Panapola" },
{ id: 1051, name: "Panawala" },
{ id: 1052, name: "Pandukabayapura" },
{ id: 1053, name: "Pandulagama" },
{ id: 1054, name: "Panichankerny" },
{ id: 1055, name: "Panideniya" },
{ id: 1056, name: "Panirendawa" },
{ id: 1057, name: "Pankudaveli" },
{ id: 1058, name: "Pankulam" },
{ id: 1059, name: "pannala" },
{ id: 1060, name: "Pannalagama" },
{ id: 1061, name: "Pannila" },
{ id: 1062, name: "Pannimulla Panagoda" },
{ id: 1063, name: "Pannipitiya" },
{ id: 1064, name: "Panvila" },
{ id: 1065, name: "Panwilatenna" },
{ id: 1066, name: "Paradeka" },
{ id: 1067, name: "paragahakele" },
{ id: 1068, name: "Paragala" },
{ id: 1069, name: "Paragastota" },
{ id: 1070, name: "Paragoda" },
{ id: 1071, name: "Paraigama" },
{ id: 1072, name: "Parakaduwa" },
{ id: 1073, name: "Parakumpura(Anuradhapura)" },
{ id: 1074, name: "Parana Thanayamgoda" },
{ id: 1075, name: "Parapamulla" },
{ id: 1076, name: "Parape" },
{ id: 1077, name: "Parasangahawewa" },
{ id: 1078, name: "Pasbage" },
{ id: 1079, name: "Pasgoda" },
{ id: 1080, name: "passara" },
{ id: 1081, name: "Pasyala" },
{ id: 1082, name: "Pathanewatta" },
{ id: 1083, name: "Pattampitiya" },
{ id: 1084, name: "Pattitalawa" },
{ id: 1085, name: "Pattiya Watta" },
{ id: 1086, name: "Pattiyagedara" },
{ id: 1087, name: "Pattiyawatta" },
{ id: 1088, name: "payagala" },
{ id: 1089, name: "Pebotuwa" },
{ id: 1090, name: "Pelagahatenna" },
{ id: 1091, name: "Pelanda" },
{ id: 1092, name: "Pelawatta (matugama)" },
{ id: 1093, name: "Peliyagoda" },
{ id: 1094, name: "Pelmadulla" },
{ id: 1095, name: "Pemaduwa" },
{ id: 1096, name: "Penetiyana" },
{ id: 1097, name: "Pepiliyawala" },
{ id: 1098, name: "Peradeniya" },
{ id: 1099, name: "Perawella" },
{ id: 1100, name: "Perimiyankulama" },
{ id: 1101, name: "Periyamadu" },
{ id: 1102, name: "Periyaneelavanai" },
{ id: 1103, name: "Periyaporativu" },
{ id: 1104, name: "Periyapullumalai" },
{ id: 1105, name: "Pesalai" },
{ id: 1106, name: "Pethiyagoda kelaniya" },
{ id: 1107, name: "Pihimbiyagolewa" },
{ id: 1108, name: "Pilawala" },
{ id: 1109, name: "Pilimatalawa" },
{ id: 1110, name: "Piliyandala" },
{ id: 1111, name: "Pillaiyaradi" },
{ id: 1112, name: "Pimbura" },
{ id: 1113, name: "Pinnawala" },
{ id: 1114, name: "Pita Kotte" },
{ id: 1115, name: "Pitabeddara" },
{ id: 1116, name: "Pitagaldeniya" },
{ id: 1117, name: "pitakotte" },
{ id: 1118, name: "Pitamaruwa" },
{ id: 1119, name: "Pitapola" },
{ id: 1120, name: "Pitigala" },
{ id: 1121, name: "Pitipana Homagama" },
{ id: 1122, name: "Piyangala" },
{ id: 1123, name: "Poddala" },
{ id: 1124, name: "Poholiyadda" },
{ id: 1125, name: "Point Pedro" },
{ id: 1126, name: "Pokunuwita" },
{ id: 1127, name: "Polgampola" },
{ id: 1128, name: "Polgasowita" },
{ id: 1129, name: "Polgolla" },
{ id: 1130, name: "Polhengoda" },
{ id: 1131, name: "Pollebedda" },
{ id: 1132, name: "Polpithimukulana" },
{ id: 1133, name: "Polwaga Janapadaya" },
{ id: 1134, name: "Porawagama" },
{ id: 1135, name: "Poruwedanda" },
{ id: 1136, name: "Pothdeniya" },
{ id: 1137, name: "Pothukoladeniya" },
{ id: 1138, name: "Pothupitiya (ratnapura)" },
{ id: 1139, name: "Pothuwatawana" },
{ id: 1140, name: "Pottuvil" },
{ id: 1141, name: "Pubbogama" },
{ id: 1142, name: "Pudukudiruppu (Mannar)" },
{ id: 1143, name: "Pugoda" },
{ id: 1144, name: "Puhulpola" },
{ id: 1145, name: "Puhulwella" },
{ id: 1146, name: "Pujapitiya" },
{ id: 1147, name: "Pulmoddai" },
{ id: 1148, name: "Pulukunawa" },
{ id: 1149, name: "Pulungasmulla" },
{ id: 1150, name: "Punanai" },
{ id: 1151, name: "Punewa" },
{ id: 1152, name: "Punkudutivu" },
{ id: 1153, name: "Pupuressa" },
{ id: 1154, name: "Pussellawa" },
{ id: 1155, name: "Puthukudiyiruppu" },
{ id: 1156, name: "Puttalam" },
{ id: 1157, name: "Puttalam Cement Factory" },
{ id: 1158, name: "Putuhapuwa" },
{ id: 1159, name: "Puwakpitiya" },
{ id: 1160, name: "Radavana" },
{ id: 1161, name: "Radawadunna" },
{ id: 1162, name: "Radawana" },
{ id: 1163, name: "Radawela" },
{ id: 1164, name: "Raddolugama" },
{ id: 1165, name: "Ragama" },
{ id: 1166, name: "Rajagalatenna" },
{ id: 1167, name: "Rajagalathenna" },
{ id: 1168, name: "Rajagiriya" },
{ id: 1169, name: "Rajakadaluwa" },
{ id: 1170, name: "Rajanganaya" },
{ id: 1171, name: "Rajawaka" },
{ id: 1172, name: "Rajawella" },
{ id: 1173, name: "Rakwana" },
{ id: 1174, name: "Rambewa" },
{ id: 1175, name: "Rambukkana" },
{ id: 1176, name: "Rambukpitiya" },
{ id: 1177, name: "Rambukwella" },
{ id: 1178, name: "Rammalawarapitiya" },
{ id: 1179, name: "Ranakeliya" },
{ id: 1180, name: "Ranala" },
{ id: 1181, name: "Rangala" },
{ id: 1182, name: "Ranmuduwewa" },
{ id: 1183, name: "Ranna" },
{ id: 1184, name: "Ranorawa" },
{ id: 1185, name: "Ranpathwila" },
{ id: 1186, name: "Ransegoda" },
{ id: 1187, name: "Rantembe" },
{ id: 1188, name: "Rantotuwila" },
{ id: 1189, name: "Ranwala" },
{ id: 1190, name: "Rassagala" },
{ id: 1191, name: "Ratgama" },
{ id: 1192, name: "Rathmalgahawewa" },
{ id: 1193, name: "Rathukohodigala" },
{ id: 1194, name: "Ratkarawwa" },
{ id: 1195, name: "Ratmalana" },
{ id: 1196, name: "Ratmale (Matara)" },
{ id: 1197, name: "Ratmalwala" },
{ id: 1198, name: "Ratna Hangamuwa" },
{ id: 1199, name: "ratnapura" },
{ id: 1200, name: "rattota" },
{ id: 1201, name: "Remunagoda" },
{ id: 1202, name: "Ridimaliyadda" },
{ id: 1203, name: "Rikillagaskada" },
{ id: 1204, name: "Rilpola" },
{ id: 1205, name: "Rottawewa" },
{ id: 1206, name: "Rotumba" },
{ id: 1207, name: "Ru/ridiyagama" },
{ id: 1208, name: "Ruggahawila" },
{ id: 1209, name: "Rukmale" },
{ id: 1210, name: "Ruwanwella" },
{ id: 1211, name: "Sainthamaruthu" },
{ id: 1212, name: "saithamarthu" },
{ id: 1213, name: "Saliyapura" },
{ id: 1214, name: "Saliyawewa Junction" },
{ id: 1215, name: "Samanalawewa" },
{ id: 1216, name: "samanturai" },
{ id: 1217, name: "Sampaltivu" },
{ id: 1218, name: "Sampur" },
{ id: 1219, name: "Sangarajapura" },
{ id: 1220, name: "Seaforth Colony" },
{ id: 1221, name: "Seeduwa" },
{ id: 1222, name: "Seeppukulama" },
{ id: 1223, name: "Senapura" },
{ id: 1224, name: "Senarathwela" },
{ id: 1225, name: "Serankada" },
{ id: 1226, name: "Serukele" },
{ id: 1227, name: "Serunuwara" },
{ id: 1228, name: "Seruwila" },
{ id: 1229, name: "Siddamulla" },
{ id: 1230, name: "Silavathurai" },
{ id: 1231, name: "Silmiyapura" },
{ id: 1232, name: "Sirajnagar" },
{ id: 1233, name: "Sirambiadiya" },
{ id: 1234, name: "Sirigala" },
{ id: 1235, name: "Sirimalgoda" },
{ id: 1236, name: "siripura" },
{ id: 1237, name: "Siwalakulama" },
{ id: 1238, name: "Siyambalagashene" },
{ id: 1239, name: "Siyambalagoda" },
{ id: 1240, name: "Siyambalape" },
{ id: 1241, name: "Siyambalawewa" },
{ id: 1242, name: "Siyambalewa" },
{ id: 1243, name: "Slave Island" },
{ id: 1244, name: "Somapura" },
{ id: 1245, name: "Sooriyawewa Town" },
{ id: 1246, name: "Sorabora Colony" },
{ id: 1247, name: "Soragune" },
{ id: 1248, name: "Soranatota" },
{ id: 1249, name: "Spring Valley" },
{ id: 1250, name: "Sravasthipura" },
{ id: 1251, name: "Sri Jayawardenepura" },
{ id: 1252, name: "Sri Palabaddala" },
{ id: 1253, name: "Sudagala" },
{ id: 1254, name: "Sultanagoda" },
{ id: 1255, name: "Tabbowa" },
{ id: 1256, name: "Talagampola" },
{ id: 1257, name: "Talahena" },
{ id: 1258, name: "Talakolahinna" },
{ id: 1259, name: "Talatuoya" },
{ id: 1260, name: "Talawa" },
{ id: 1261, name: "Talawatugoda" },
{ id: 1262, name: "Talawila Church" },
{ id: 1263, name: "Taldena" },
{ id: 1264, name: "Talgaspe" },
{ id: 1265, name: "Talgaspitiya" },
{ id: 1266, name: "Talgaswela" },
{ id: 1267, name: "Talpe" },
{ id: 1268, name: "Tambuttegama" },
{ id: 1269, name: "Tammennawa" },
{ id: 1270, name: "Tampalakamam" },
{ id: 1271, name: "Tangalla" },
{ id: 1272, name: "Tanjantenna" },
{ id: 1273, name: "Tantirimale" },
{ id: 1274, name: "Tawalama" },
{ id: 1275, name: "Tawalantenna" },
{ id: 1276, name: "Tebuwana" },
{ id: 1277, name: "Teldeniya" },
{ id: 1278, name: "Teligama" },
{ id: 1279, name: "Telijjawila" },
{ id: 1280, name: "Tempitiya" },
{ id: 1281, name: "Tennekumbura" },
{ id: 1282, name: "Tennepanguwa" },
{ id: 1283, name: "Teppanawa" },
{ id: 1284, name: "Thalaimannar" },
{ id: 1285, name: "Thambiluvil" },
{ id: 1286, name: "Thannamunai" },
{ id: 1287, name: "Thepelpola" },
{ id: 1288, name: "Thettativu" },
{ id: 1289, name: "Thihagoda" },
{ id: 1290, name: "Thikkodai" },
{ id: 1291, name: "Thimbirigaskatuwa" },
{ id: 1292, name: "Thirunelvely" },
{ id: 1293, name: "Thirupalugamam" },
{ id: 1294, name: "Tholangamuwa" },
{ id: 1295, name: "Thuraineelavanai" },
{ id: 1296, name: "Timbirigaspitiya" },
{ id: 1297, name: "Tiranagama" },
{ id: 1298, name: "Tirappane" },
{ id: 1299, name: "Tiriyayi" },
{ id: 1300, name: "Tirukovil" },
{ id: 1301, name: "Tissamaharama" },
{ id: 1302, name: "Tittagonewa" },
{ id: 1303, name: "Tittapattara" },
{ id: 1304, name: "Toduwawa" },
{ id: 1305, name: "Toppur" },
{ id: 1306, name: "Trincomalee" },
{ id: 1307, name: "Tummodara" },
{ id: 1308, name: "Tunkama" },
{ id: 1309, name: "Uda Gomadiya" },
{ id: 1310, name: "Uda Peradeniya" },
{ id: 1311, name: "Udaha Hawupe" },
{ id: 1312, name: "Udahentenna" },
{ id: 1313, name: "Udahingulwala" },
{ id: 1314, name: "Udakarawita" },
{ id: 1315, name: "Udalamatta" },
{ id: 1316, name: "Udamattala" },
{ id: 1317, name: "Udaniriella" },
{ id: 1318, name: "Udappuwa" },
{ id: 1319, name: "Udatalawinna" },
{ id: 1320, name: "Udathuthiripitiya" },
{ id: 1321, name: "Udawalawe" },
{ id: 1322, name: "Udawatta" },
{ id: 1323, name: "Udispattuwa" },
{ id: 1324, name: "Ududumbara" },
{ id: 1325, name: "Udugama" },
{ id: 1326, name: "Udugampola" },
{ id: 1327, name: "Uduhawara" },
{ id: 1328, name: "udupussellawa" },
{ id: 1329, name: "Uduwa" },
{ id: 1330, name: "Uduwahinna" },
{ id: 1331, name: "Uduwara" },
{ id: 1332, name: "Uduwela" },
{ id: 1333, name: "Uggalboda" },
{ id: 1334, name: "Uhana" },
{ id: 1335, name: "Ukuwela" },
{ id: 1336, name: "Ulapane" },
{ id: 1337, name: "Ullinduwawa" },
{ id: 1338, name: "Ulpothagama" },
{ id: 1339, name: "Uluvitike" },
{ id: 1340, name: "Unawatuna" },
{ id: 1341, name: "Unenwitiya" },
{ id: 1342, name: "Unnichchai" },
{ id: 1343, name: "Unuwinna" },
{ id: 1344, name: "Upuldeniya" },
{ id: 1345, name: "Uragaha" },
{ id: 1346, name: "Uragasmanhandiya" },
{ id: 1347, name: "Uraniya" },
{ id: 1348, name: "Urapola" },
{ id: 1349, name: "Urubokka" },
{ id: 1350, name: "Urugamuwa" },
{ id: 1351, name: "Urumpirai" },
{ id: 1352, name: "Urumutta" },
{ id: 1353, name: "Uswetakeiyawa" },
{ id: 1354, name: "Uswewa" },
{ id: 1355, name: "Uttimaduwa" },
{ id: 1356, name: "Utumgama" },
{ id: 1357, name: "Uva Deegalla" },
{ id: 1358, name: "Uva Karandagolla" },
{ id: 1359, name: "Uva Mawelagama" },
{ id: 1360, name: "Uva Tenna" },
{ id: 1361, name: "Uva Tissapura" },
{ id: 1362, name: "Uva Uduwara" },
{ id: 1363, name: "Uvaparanagama" },
{ id: 1364, name: "Uyilankulam" },
{ id: 1365, name: "Vakaneri" },
{ id: 1366, name: "Vakarai" },
{ id: 1367, name: "Valaichenai" },
{ id: 1368, name: "Vanathawilluwa" },
{ id: 1369, name: "Vantharumoolai" },
{ id: 1370, name: "Vavuniya" },
{ id: 1371, name: "Veddagala" },
{ id: 1372, name: "Velamboda" },
{ id: 1373, name: "Vellamanal" },
{ id: 1374, name: "Vellavely" },
{ id: 1375, name: "Velvettithurai" },
{ id: 1376, name: "Veyangalla" },
{ id: 1377, name: "Veyangoda" },
{ id: 1378, name: "Viharahena" },
{ id: 1379, name: "Viharapalugama" },
{ id: 1380, name: "Vijeriya" },
{ id: 1381, name: "Vijithapura" },
{ id: 1382, name: "Vilanagama" },
{ id: 1383, name: "Vitharandeniya" },
{ id: 1384, name: "Wadduwa" },
{ id: 1385, name: "Wadinagala" },
{ id: 1386, name: "Waga" },
{ id: 1387, name: "Wahalkada" },
{ id: 1388, name: "Wahamalgollewa" },
{ id: 1389, name: "Waikkal" },
{ id: 1390, name: "Wakwella" },
{ id: 1391, name: "Walagambahuwa" },
{ id: 1392, name: "Walagedara" },
{ id: 1393, name: "Walahanduwa" },
{ id: 1394, name: "Walahaviddawewa" },
{ id: 1395, name: "Walakanda" },
{ id: 1396, name: "Walallawita" },
{ id: 1397, name: "walapane" },
{ id: 1398, name: "Walasgala" },
{ id: 1399, name: "Walasmulla" },
{ id: 1400, name: "Waleboda" },
{ id: 1401, name: "Walgammulla" },
{ id: 1402, name: "Walpita" },
{ id: 1403, name: "Walpola (wp)" },
{ id: 1404, name: "Wanagamuwa" },
{ id: 1405, name: "Wanaluwewa" },
{ id: 1406, name: "wanathawilluwa" },
{ id: 1407, name: "Wanchawela" },
{ id: 1408, name: "Wanduramba" },
{ id: 1409, name: "Wanela" },
{ id: 1410, name: "Waralla" },
{ id: 1411, name: "Wariyapola" },
{ id: 1412, name: "Warukandeniya" },
{ id: 1413, name: "Waskaduwa" },
{ id: 1414, name: "Watagoda Harispattuwa" },
{ id: 1415, name: "Watapotha" },
{ id: 1416, name: "Watareka (Padukka)" },
{ id: 1417, name: "Wathurugama" },
{ id: 1418, name: "Watinapaha" },
{ id: 1419, name: "Wattala" },
{ id: 1420, name: "Wattappola (kandy)" },
{ id: 1421, name: "Wattegama" },
{ id: 1422, name: "Watugahamulla" },
{ id: 1423, name: "Watugedara" },
{ id: 1424, name: "Waturawa" },
{ id: 1425, name: "Weboda" },
{ id: 1426, name: "Weeraketiya" },
{ id: 1427, name: "Weerawila" },
{ id: 1428, name: "Weerawila Newtown" },
{ id: 1429, name: "Wegowwa" },
{ id: 1430, name: "Weihena" },
{ id: 1431, name: "Wekandawela" },
{ id: 1432, name: "Weligalla" },
{ id: 1433, name: "Weligama" },
{ id: 1434, name: "Weligampola" },
{ id: 1435, name: "Weligatta" },
{ id: 1436, name: "Weligepola" },
{ id: 1437, name: "welimada" },
{ id: 1438, name: "Welimuwapotana" },
{ id: 1439, name: "Welioya Project" },
{ id: 1440, name: "Welipathayaya" },
{ id: 1441, name: "Welipenna" },
{ id: 1442, name: "Weliveriya" },
{ id: 1443, name: "wellampitiya" },
{ id: 1444, name: "Welmilla Junction" },
{ id: 1445, name: "Wendaruwa" },
{ id: 1446, name: "Wennappuwa" },
{ id: 1447, name: "Weragantota" },
{ id: 1448, name: "Werapitya" },
{ id: 1449, name: "Werellagama" },
{ id: 1450, name: "Werunketagoda" },
{ id: 1451, name: "Wettawa" },
{ id: 1452, name: "Weweldeniya" },
{ id: 1453, name: "Wewelwatta" },
{ id: 1454, name: "Wijerama" },
{ id: 1455, name: "Wijeyakatupotha" },
{ id: 1456, name: "Wikiliya" },
{ id: 1457, name: "Wilanagama" },
{ id: 1458, name: "Wilpita" },
{ id: 1459, name: "Wilpotha" },
{ id: 1460, name: "Yagirala" },
{ id: 1461, name: "Yahalatenna" },
{ id: 1462, name: "Yakkala" },
{ id: 1463, name: "Yakkalamulla" },
{ id: 1464, name: "Yatadolawatta" },
{ id: 1465, name: "Yatalamatta" },
{ id: 1466, name: "Yatawara Junction" },
{ id: 1467, name: "Yatigala" },
{ id: 1468, name: "Yatihalagala" },
{ id: 1469, name: "Yatiyana (Gampaha)" },
{ id: 1470, name: "Yatiyana (Matara)" },
{ id: 1471, name: "Yogiyana" },
];

export default CityList;