import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../../helpers/CommonHelper";
import GlobalEnums from "../../../../helpers/GlobalEnums";
import rootAction from "../../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../../helpers/Constants";
import LockIcon from "@mui/icons-material/Lock";

const MenuUserSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openAccount, setOpenAccount] = useState(false);
  const [localizationLabels, setLocalizationLabels] = useState([]);
  const [langCode, setLangCode] = useState("");

  const loginUser = JSON.parse(
    useSelector((state) => state.userReducer.user) ?? "{}"
  );

  const handleNavigation = (path) => {
    setOpenAccount(false);
    navigate(`/${getLanguageCodeFromSession()}${path}`);
  };

  const handleLogout = () => {
    localStorage.setItem("user", JSON.stringify("{}"));
    dispatch(rootAction.userAction.setUser("{}"));
    setOpenAccount(false);
    navigate("/", { replace: true });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const langCode = getLanguageCodeFromSession();
        setLangCode(langCode);

        const localizationData = await GetLocalizationControlsJsonDataForScreen(
          GlobalEnums.Entities["MegaMenu"],
          null
        );

        if (localizationData?.length) {
          setLocalizationLabels(localizationData);
        }
      } catch (error) {
        console.error("Error fetching localization data:", error);
      } finally {
        dispatch(rootAction.commonAction.setLoading(false));
      }
    };

    dispatch(rootAction.commonAction.setLoading(true));
    fetchData();

    return () => {
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    };
  }, [dispatch]);

  return (
    <>
      {!loginUser?.UserID ? (
        <li style={{ marginRight: "1rem" }}>
          <Link
            to={`/${getLanguageCodeFromSession()}/login`}
            className="btn"
            onClick={() => setOpenAccount(false)}
          >
            <LockIcon style={{ color: "white", marginBottom: "4px" }} />
          </Link>
        </li>
      ) : (
        <p></p>
      )}

      <li
        className="mobile-user onhover-dropdown"
        onClick={() => setOpenAccount(!openAccount)}
      >
        <a href="#">
          {loginUser?.UserID ? (
            <h5 style={{ color: "#fff" }}>Hi {loginUser.FirstName}!</h5>
          ) : null}
        </a>
      </li>

      <div
        className={`add_to_cart right account-bar ${
          openAccount ? "open-side" : ""
        }`}
      >
        <a
          href="#"
          className="overlay"
          onClick={() => setOpenAccount(!openAccount)}
        ></a>
        <div className="cart-inner">
          <div className="cart_top">
            <h3>My Account</h3>
            <div className="close-cart">
              <a href="#" onClick={() => setOpenAccount(!openAccount)}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </a>
            </div>
          </div>

          <Form className="userForm">
            <FormGroup>
              {loginUser?.UserID ? (
                <>
                  <div className="title_login">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavigation("/update-profile");
                      }}
                    >
                      {localizationLabels.length > 0
                        ? replaceLoclizationLabel(
                            localizationLabels,
                            "Update Profile",
                            "lbl_loginmodal_profile"
                          )
                        : "Update Profile"}
                    </Link>
                  </div>
                  <div className="title_login">
                    <Link
                      to="#"
                      className="return-store"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavigation("/orders-history");
                      }}
                    >
                      {localizationLabels.length > 0
                        ? replaceLoclizationLabel(
                            localizationLabels,
                            "Order History",
                            "lbl_loginmodal_orderhistory"
                          )
                        : "Order History"}
                    </Link>
                  </div>

                  <Link
                    to="#"
                    className="btn btn-rounded btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogout();
                    }}
                  >
                    {localizationLabels.length > 0
                      ? replaceLoclizationLabel(
                          localizationLabels,
                          "Logout",
                          "lbl_mgmenu_logout"
                        )
                      : "Logout"}
                  </Link>
                </>
              ) : (
                <Link
                  to={`/${getLanguageCodeFromSession()}/login`}
                  className="btn btn-rounded btn-block"
                  onClick={() => setOpenAccount(false)}
                >
                  {localizationLabels.length > 0
                    ? replaceLoclizationLabel(
                        localizationLabels,
                        "Login",
                        "lbl_mgmenu_login"
                      )
                    : "Login"}
                </Link>
              )}
            </FormGroup>
          </Form>
        </div>
      </div>
    </>
  );
};

export default MenuUserSection;
