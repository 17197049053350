// CustomModal.js
import React from 'react';
import './CustomModal.css'; 

const CustomModal = ({ isOpen, onConfirm, onCancel, message, bgClassName }) => {
  if (!isOpen) return null;

  return (
    <div className={`custom-modal `}>
      <div className={`custom-modal-content ${bgClassName ? bgClassName : ''}`}>
        <p>{message}</p>
        <button className='btn yes' onClick={onConfirm}>Proceed with Cash on Delivery</button>
        <button className='btn no' onClick={onCancel}>Change Payment & Win Prizes</button>
      </div>
    </div>
  );
};

export default CustomModal;
