import React, { Fragment, useContext, useEffect, Component, useState } from "react";
import { Container, Row, Col, Media } from "reactstrap";
import MenuCategory from "./MenuCategory";
import HorizaontalMenu from "./HorizaontalMenu";
import MobileSearch from "./MobileSearch";
import MenuContactUs from "./MenuContactUs";
import MenuGift from "./MenuGift";
import MenuUserSection from "./MenuUserSection";
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import Wishlist from "./Wishlist";
import { showInfoMsg } from "../../../../helpers/ValidationHelper";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import {
    getLanguageCodeFromSession,
    GetLocalizationControlsJsonDataForScreen,
    replaceLoclizationLabel,
} from "../../../../helpers/CommonHelper";

const MegaMenu = () => {
    const navigate = useNavigate();
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const totalCartItems = useSelector(
        (state) => state.cartReducer.totalCartItems
    );

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCart = (event) => {
        event.preventDefault();

        if (
            totalCartItems != null &&
            totalCartItems != null &&
            totalCartItems > 0
        ) {
            navigate("/" + getLanguageCodeFromSession() + "/cart");
        } else {
            showInfoMsg("No item exists in your cart");
        }
    };


    return (
        <>

            <div className="custom-container">
                <Row>
                    <Col>
                        <div className="navbar-menu">
                            <div className="category-left">
                                {/* <MenuCategory /> */}

                                <div className="icon-block">
                                    <ul>
                                        <div className="mobile-fix-option"></div>

                                        <MenuUserSection />

                                        {isMobile && (
                                            <>
                                                <div className="cart-block cart-hover-div" style={{ display: 'inline-block', marginRight: '20px' }}>
                                                    <div className="cart ">
                                                        <span style={{ color: '#8e8e3e' }} className="cart-product"> {totalCartItems ?? 0} </span>
                                                        <ul>
                                                            <li className="mobile-cart">
                                                                <Link
                                                                    to="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleCart(e);
                                                                    }}
                                                                >
                                                                    <i style={{ color: '#fff' }} className="icon-shopping-cart "></i>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </>
                                        )}

                                        <Wishlist />

                                        {/* cart */}
                                        
                                        <MobileSearch />
                                        {/* <MobileSetting /> */}
                                    </ul>
                                </div>
                                <HorizaontalMenu />
                            </div>
                            <div className="category-right">
                                {/* <MenuContactUs spanClass="" /> */}
                                {/* <MenuGift /> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );

}


export default MegaMenu;
